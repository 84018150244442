<template>
  <v-container fluid class="mx-4">
    <v-row>
      <v-card v-if="item" width="90%" class="mx-auto mt-6">
        <!-- Header -->
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="headline">
              {{ item.$calculated.$templateName }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span>
                {{ $t("createdAt") }}:
                {{ item.createdAt | getDisplayDate }}
                ({{ item.$calculated.$createdBy | empty }})
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <div class="d-flex flex-row">
          <!-- Preview -->
          <div class="preview-container">
            <GsReportPreview v-if="item" :value="item.reportId" />
          </div>

          <!-- Details -->
          <div style="width: 20%">
            <v-card-text>
              <v-list-item two-line v-if="showAssets(item.inputValues.items)">
                <v-list-item-content>
                  <v-list-item-subtitle>{{
                    $t("assets")
                  }}</v-list-item-subtitle>
                  <span class="subtitle-1">{{
                    showAssets(item.inputValues.items)
                  }}</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line v-if="showDrivers(item.inputValues.items)">
                <v-list-item-content>
                  <v-list-item-subtitle>{{
                    $t("drivers")
                  }}</v-list-item-subtitle>
                  <span class="subtitle-1">{{
                    showDrivers(item.inputValues.items)
                  }}</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line v-if="showGeoZones(item.inputValues.items)">
                <v-list-item-content>
                  <v-list-item-subtitle>{{
                    $t("geoZones")
                  }}</v-list-item-subtitle>
                  <span class="subtitle-1">{{
                    showGeoZones(item.inputValues.items)
                  }}</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>{{
                    $t("start_date")
                  }}</v-list-item-subtitle>
                  <v-list-item-title
                    v-show="item.inputValues.startDate"
                    class="subtitle-1"
                    >{{
                      item.inputValues.startDate
                        | getDisplayDate("date")
                        | empty
                    }}</v-list-item-title
                  >
                  <v-list-item-title
                    v-show="!item.inputValues.startDate"
                    class="subtitle-1"
                    >{{
                      item.createdAt | getDisplayDate("date") | empty
                    }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle>{{
                    $t("end_date")
                  }}</v-list-item-subtitle>
                  <v-list-item-title class="subtitle-1">{{
                    item.inputValues.endDate | getDisplayDate("date") | empty
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- actions -->
        <v-card-actions class="py-4 px-8">
          <v-icon
            v-if="isDeleteReportAllowed()"
            class="mr-4 my-2"
            @click="deleteReport(item.reportId, item.jobId)"
            >mdi mdi-delete</v-icon
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="item.status.split('.').pop() === 'success'"
            @click="downloadReport(item.reportId, item.format)"
          >
            <v-icon :color="showReportFormatColor(item.format)">{{
              showReportFormat(item.format)
            }}</v-icon>
            {{ $t("download") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import GsReportPreview from "@/components/GsReportPreview";
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;
const { fileSave } = require("@/utils/fileSave.js");

export default {
  name: "ReportDetails",
  components: {
    GsReportPreview,
  },
  data() {
    return {
      item: null,
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler(value) {
        this.getReport(value.params.reportId);
      },
    },
  },
  methods: {
    async getReport(reportId) {
      this.$store.state.loading = true;

      try {
        // const url = `${process.env.VUE_APP_BFF_ORIGIN}/reports?${reportId}`
        const url = `/reports?filter=reportId:eq:${reportId}&r8sFields=createdBy.name`;
        const result = await callBffAPI({
          url,
          method: "GET",
        });
        const item = result.data[0];
        const templateIds = [item.templateId];
        const templateNames = await this.getTemplateNamesByIds(templateIds);

        item.$calculated = item.$calculated || {};
        item.$calculated.$templateName = templateNames[item.templateId];
        item.$calculated.$createdBy = item?.createdBy?.name || "";

        this.item = item;
        this.$store.state.loading = false;
      } catch (error) {
        this.$store.state.loading = false;
        this.errorSnackbar(error);
      }
    },
    async downloadReport(reportId, format) {
      try {
        const { data, headers } = await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/reports/${reportId}/fileContent`,
          method: "GET",
          resType: "blob",
        });
        const fileName = headers["content-disposition"]
          ? headers["content-disposition"].match(/filename="(.*)"/)[1]
          : `${reportId}.${format}`;
        fileSave(data, fileName);
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async deleteReport(reportId, jobId) {
      if (!this.isDeleteReportAllowed()) return;

      const result = confirm(this.$t("delete_modal"));
      if (!result) return;

      try {
        const url = `${process.env.VUE_APP_BFF_ORIGIN}/reports/${reportId}`;
        const result = await callAPI({ url, method: "DELETE" });
        if (result.status !== 204) return;
        await this.deleteJob(jobId);

        this.$router.push_safe({ name: "Reports List" });
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    isDeleteReportAllowed() {
      return (
        this.$store &&
        this.$store.state.user &&
        this.$store.state.user.owner &&
        this.$store.state.user.owner.root
      );
    },
  },
};
</script>

<style scoped>
.preview-container {
  width: 80%;
  height: calc(100vh - 255px);
}
</style>
