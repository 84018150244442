<template>
  <div class="responsive-iframe-container">
    <!-- <iframe class="responsive-iframe" :src="content" v-if="content" /> -->
    <div
      v-html="content"
      v-if="!loading && content"
      style="max-height: 100%; overflow-y: scroll; line-height: 1"
    />

    <!-- loading -->
    <v-sheet style="text-align: center" class="pt-14" v-if="loading">
      <v-progress-circular
        :size="20"
        :width="2"
        color="grey"
        indeterminate
      ></v-progress-circular>
      <span class="ml-2">{{ $t("loading_data") }}</span>
    </v-sheet>

    <!-- not_found -->
    <v-sheet
      style="text-align: center"
      class="pt-14"
      v-if="!loading && !content"
    >
      <v-icon large class="mr-2"> mdi mdi-cancel </v-icon>
      <span>{{ $t("document_not_found") }}</span>
    </v-sheet>
  </div>
  <!-- <embed type="text/html" :src="url" width="100%" height="400" /> -->
</template>

<script>
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "GsReportPreview",
  props: {
    value: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      baseUrl: null,
      content: null,
      loading: false,
    };
  },
  watch: {
    value() {
      this.update();
    },
  },
  created() {
    // console.log("created")
    this.update();
    // this.$router.afterEach((to, from) => {
    //   if (from.name === "Report Details") {

    //     console.log("AFTER_EACH 2", to, from)
    //   }
    // });
  },
  methods: {
    async update() {
      if (!this.value) {
        this.content = null;
        return;
      }

      try {
        this.loading = true;
        this.baseUrl = `/reports/${this.value}/preview/`;

        let html = await this.downloadHtml();
        if (!html) {
          this.content = null;
          return;
        }

        const { imageUrls, scriptUrls, scripts } = this.parseHtml(html);
        // console.log(imageUrls)

        await this.downloadScripts(scriptUrls, scripts);

        const images = await this.downloadImages(imageUrls);
        // console.log(images)

        html = await this.replaceImages(html, images);
        // const base64 = this.encodeBase64(html)
        // console.log(base64)

        // this.content = `data:text/html;base64, ${base64}`;
        this.content = html;
      } finally {
        this.loading = false;
      }
    },
    async downloadHtml() {
      try {
        // TEST
        // const url = `${this.baseUrl}${this.url}`
        // const response = await callAPI({ url, method: "GET", cache: true })

        const url = `/reports/${this.value}/preview`;
        const response = await callAPI({ url, method: "GET", cache: true });

        if (response.status !== 200) {
          throw response.status;
        }

        // console.log(response)
        return response.data;
      } catch (err) {
        // console.log(err)
        return null;
      }
    },
    parseHtml(htmlString) {
      // const tempContainer = document.createElement("div"); // it downloads images!!!
      // tempContainer.innerHTML = html;
      const parser = new DOMParser();
      const tempContainer = parser.parseFromString(htmlString, "text/html");

      let imageUrls = [...tempContainer.getElementsByTagName("img")].map((p) =>
        p.getAttribute("src")
      );
      imageUrls = imageUrls.map((p) => p.replace(/^.\//, ""));
      imageUrls = this.distinct(imageUrls);

      let scriptUrls = [...tempContainer.getElementsByTagName("script")]
        .map((p) => p.getAttribute("src"))
        .filter((p) => p);
      // console.log(scriptUrls)

      let scripts = [...tempContainer.getElementsByTagName("script")]
        .map((p) => p.textContent)
        .filter((p) => p);

      return { imageUrls, scriptUrls, scripts };
    },
    async downloadScripts(scriptUrls, scripts) {
      if (typeof jasperreports === "undefined" && scriptUrls.length === 1) {
        let p = scriptUrls[0];
        var script = document.createElement("script");
        script.id = "GOOGLE_MAPS";
        script.src = p;
        document.head.appendChild(script);
      }

      setTimeout(() => {
        for (let script of scripts) {
          var scriptTag = document.createElement("script");
          scriptTag.innerText = script;
          document.body.appendChild(scriptTag);
        }
      }, 2000);
    },
    async downloadImages(imageUrls) {
      // console.log("downloadImages", imageUrls)
      // TEST
      // const tasks = imageUrls.map(p => callAPI({ url: `${this.baseUrl}${p}`, method: "GET", resType: "blob", cache: true }))

      const tasks = imageUrls.map((p) =>
        callAPI({
          url: `${this.baseUrl}${p}`,
          method: "GET",
          resType: "blob",
          cache: false,
        })
      );

      const responses = await Promise.all(tasks);
      // console.log(responses)
      const r = responses.map((p) => ({
        url: p.config.url,
        relUri: p.config.url.substring(this.baseUrl.length),
        blob: p.data,
      }));
      // console.log(r)
      const tasks2 = r.map((p) => this.blobToBase64(p.blob));
      const images = await Promise.all(tasks2);
      const result = images.map((p, i) => ({ ...r[i], base64: p }));
      // console.log(result)
      return result;
    },
    async replaceImages(html, images) {
      const tempContainer = document.createElement("div");
      tempContainer.innerHTML = html;
      const elements = [...tempContainer.getElementsByTagName("img")];
      elements.forEach((el) => {
        let src = el.getAttribute("src");
        // console.log(src, images)

        src = src.replace(/^.\//, "");
        // console.log(src, images)

        const img = images.find((p) => p.relUri == src);
        // console.log(img)

        if (img) {
          el.setAttribute("src", img.base64);
        } else {
          // set image placeholder here
          el.setAttribute("style", "background-color: #cbcbcb;");
        }
      });
      return tempContainer.innerHTML;
    },
    encodeBase64(stuff) {
      const data = new Buffer(stuff);
      return data.toString("base64");
    },
    async blobToBase64(blob) {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    },
  },
};
</script>

<style>
.responsive-iframe-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-right: 1px solid #dfdfdf;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>
